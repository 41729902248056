import { baseTheme, colors } from "./theme";
import CONSTANT from "./constants";
import { getEncPassword } from "./encrypt";
import { showToast } from "./toast";

export const useUtils = () => {
  return {
    theme: baseTheme,
    colors: colors,
    constant: CONSTANT,
    getEncPassword,
    showToast,
  };
};

const chartColors = [
  "#f05453",
  "#0087a1",
  "#06D6A0",
  "#FFD166",
  "#5995ED",
  "#044389",
  "#FFAD05",
  "#2E5077",
  "#D7E8BA",
  "#611C35",
  "#EF476F",
  "#118AB2",
];
export const getRandomColorForLine = (index) => {
  return chartColors?.[index] || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const roundAndCoalesce = (value, precision = 2) => {
  if (value === null || value === undefined) {
    return "N/A";
  }
  return parseFloat(value.toFixed(precision));
};