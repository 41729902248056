import React from "react";
import ReportsChart from "./chart/mindfullnesschart";
import { Card, Grid } from "@material-ui/core";
import Heading from "./heading/index";
import moment from "moment";
import _ from "lodash";
import { CSVLink, CSVDownload } from "react-csv";
import SessionList from "./SessionList";
import OzRawDataHandler from "../ozHandler";

const defaultRange = {
  startDate: moment().subtract(8, "month").toDate(),
  endDate: moment().toDate(),
};

const defaultChartSettings = {
  reading_taken_time: "whole_day",
  chart_data_group_by: "distinct",
};

function UsersMindfullnessHistory(props) {
  const { card, usersMindfullnessHistory, selectedTags, service } = props;
  const [dateRange, setDateRange] = React.useState(defaultRange);
  const [chartSettings, setChartSettings] =
    React.useState(defaultChartSettings);
  const [selected, setSelected] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [newOzRawData, setNewOzRawData] = React.useState(null);

  React.useEffect(() => {
    if (selected !== "") {
      setSelected("");
    }
  }, [usersMindfullnessHistory]);

  // For oz-raw-data by sessionId
  React.useEffect(() => {
    if (selected !== undefined && selected !== "") {
      setLoading(true);
      const ozRawDataHandler = new OzRawDataHandler(service);
      (async () => {
        const ozRawData = await ozRawDataHandler.getRawDataForSession(selected);
        if (ozRawData.error) {
          setNewOzRawData(null);
          setLoading(false);
        } else {
          const ozData = ozRawData.flatMap((entry) =>
            entry.oz_values.map((value, index) => ({
              dateTime: new Date(
                entry.reportStartTime + index * 1000
              ).toLocaleTimeString([], { hour12: false }),
              optimalZone: value !== null ? value : 0,
            }))
          );
          setNewOzRawData(ozData);
          setLoading(false);
        }
      })();
    }
  }, [selected, service]);

  const handleFormatData = React.useCallback(() => {
    const time_12 = moment("12:00", "HH:mm");
    // Filter users rmssd based on the chart settings
    const filteredMindfullnessHistoryWithUsers = [
      ...usersMindfullnessHistory,
    ].map((ur) => {
      const readings = ur?.readings.filter(
        ({ dateTime, tags = [], results = [] }) => {
          const date = moment(dateTime, "Y-MM-DD HH:mm");
          const term1 = date.isBetween(
            moment(dateRange.startDate),
            moment(dateRange.endDate),
            "[]"
          );
          let term2 = true;
          if (chartSettings.reading_taken_time === "until_mid_day") {
            term2 = moment(date.format("HH:mm"), "HH:mm").isSameOrBefore(
              time_12
            );
          } else if (chartSettings.reading_taken_time === "after_mid_day") {
            term2 = moment(date.format("HH:mm"), "HH:mm").isSameOrAfter(
              time_12
            );
          }
          return term1 && term2;
        }
      );
      return { ...ur, readings };
    });

    const userData = [];
    filteredMindfullnessHistoryWithUsers.forEach((user, index) => {
      const flatData = _.flatMap([user], (obj) => obj.readings);
      const session_results = flatData
        .filter((ur) => {
          if (selected === "") {
            return true;
          }
          return selected === ur.sessionId;
        })
        .map((ur) => ur.results)
        .flat();
      const sessions = flatData;
      const allReadings = flatData
        .filter((ur) => {
          if (selected === "") {
            return true;
          }
          return selected === ur.sessionId;
        })
        .sort((a, b) => {
          return new Date(a.dateTime) - new Date(b.dateTime);
        });
      const allSessionOZ = usersMindfullnessHistory[index].allTimeOzPercentage;
      const userInfo = usersMindfullnessHistory[index].user;
      userData[index] = {
        sessions,
        allReadings,
        session_results,
        allSessionOZ,
        userInfo,
      };
    });
    return userData;
  }, [
    selected,
    usersMindfullnessHistory,
    chartSettings.chart_data_group_by,
    chartSettings.reading_taken_time,
    dateRange.startDate,
    dateRange.endDate,
    selectedTags,
  ]);

  const formattedData = handleFormatData();
  const allOzData = formattedData?.map((obj) => ({
    userName: `${obj.userInfo.first_name} ${obj.userInfo.last_name}`,
    allOz: obj.allSessionOZ,
    hasData: obj.sessions.length > 0,
  }));
  const sessions = formattedData?.map((obj) => obj.sessions);
  const session_results = formattedData?.map((obj) => obj.session_results);
  const csvData = formattedData?.map((obj) => obj.allReadings);
  const data = csvData?.map((innerArray) => {
    return innerArray.map((item) => ({
      dateTime: item.dateTime,
      breathsPerMinute: item.breathsPerMinute,
      sessionMinutes: item.sessionMinutes,
      sessionId: item.sessionId,
      tooltipText: item.tooltipText,
      userId: item.userId,
      optimalZone: item.ozSesAvgPercentage,
      rmssd: item.rmssd,
      lf: item.lf,
      hf: item.hf,
      vlf: item.vlf,
      minMax: item.minMax,
      sdnn: item.sdnn,
      name: item.name,
      inhalePercentage: item.inhalePercentage,
      exhalePercentage: item.exhalePercentage,
      inhaleSeconds: item.inhaleSeconds,
      exhaleSeconds: item.exhaleSeconds,
      defaultTotalMinutes: item.defaultTotalMinutes,
      frequencyLf: item.results.map((result) => result.lf),
      frequencyHf: item.results.map((result) => result.hf),
    }));
  });

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Heading
          heading={`Users Mindfullness History`}
          withRangePicker={true}
          onSettingsChange={setChartSettings}
          defaultChartSettings={defaultChartSettings}
          defaultDateRange={defaultRange}
          handleRangeChange={setDateRange}
        />
        {csvData?.length > 0 ? (
          <CSVLink data={csvData}>Download Mindfullness History</CSVLink>
        ) : null}
        <Card variant={"outlined"} square={false} className={card}>
          {sessions?.length > 0 ? (
            <Grid container xs={12} flexDirection="row">
              <Grid item xs={4}>
                <SessionList
                  selected={selected}
                  setSelected={setSelected}
                  data={sessions}
                  results={session_results}
                  allOzData={allOzData}
                />
              </Grid>
              <Grid item xs={8}>
                <ReportsChart
                  chartSettings={chartSettings}
                  users={usersMindfullnessHistory.map((obj) => obj.user)}
                  type={"MindfullnessHistory"}
                  data={data}
                  selected={selected}
                  results={session_results}
                  ozRawData={newOzRawData}
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ReportsChart
                chartSettings={chartSettings}
                users={usersMindfullnessHistory.map((obj) => obj.user)}
                type={"MindfullnessHistory"}
                data={data}
                results={session_results}
              />
            </Grid>
          )}
        </Card>
      </Grid>
    </React.Fragment>
  );
}
export default React.memo(UsersMindfullnessHistory);
